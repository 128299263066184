header {
    background-color: #FFF;
    border-bottom: solid 1px #333;
    font-size: calc(12px + 0.35vmax);
    color: #333;
    font-weight: 500;
}

.App-logo {
    pointer-events: none;
    height: 115px;
    padding-bottom: 10px;
}

.App-header > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}