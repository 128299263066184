body {
  background-color: #333;
  font-family: 'Rajdhani';
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

input, button {
  border-radius: 0 !important;
}

address a, address a:hover {
  color: #FFF;
}

.App {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #FFF;
  align-items: center;
  justify-content: center;
}

.App-heading {
  text-align: center;
  padding-bottom: 42px;
}
