.Footer {
    padding-bottom: 1rem;
}

.Footer .row {
    display: flex;
    align-items: stretch;
}

footer {
    background-color: #FFF;
}

footer address {
    margin-top: 1rem;
    font-size: 1rem;
}

footer address a, footer address a:hover {
    color: #212529;
}

.card {
    background: transparent;
    color: #FFF;
    border: none;
    margin-bottom: 1rem;
}

.card-body {
    background-color: rgba(0,123,255,0.4);
}

.Footer img {
    background-color: rgba(255,255,255,1);
}