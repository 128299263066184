.App-form {
  padding: 5vmin;
  background-color: rgba(0,123,255,0.4);
  font-weight: 600;
}
  
.App-form h3 {
  text-align: center;
  padding-bottom: 10px;
  font-weight: bold;
}

.App-form img {
  width: 50%;
}

.Message {
  padding: 15px;
  text-align: center;
}

.Message h2 {
  margin-bottom: 0;
}

.Error {
  background-color: rgba(255,0,0,0.5);
}

.Success {
  background-color: rgba(0,255,0,0.5);
}

.btn-danger {
  background-color: #e52929;
  font-weight: 600;
}