.App-bar {
  flex-wrap: wrap;
  align-self: center;
  text-align: center;
  padding: 15px 0;
}
  
.App-bar span {
  padding: 0 5px;
}
  
.App-bar span svg {
  margin-bottom: 3px;
}